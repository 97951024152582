import React from 'react'

import AppContainer from 'common/containers/AppContainer'


const App = () => {
  return (
    <AppContainer />
  )
}

export default App
